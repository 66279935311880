<template>
  <div>
    <v-dialog
      v-model="dialog"
      :class="`${data.theme}`"
      max-width="max(30%, 384px)"
      persistent
      scrollable
      @click:outside="hideDialog()"
    >
      <v-card class="py-4 px-6">
        <div class="close-icon">
          <v-icon @click="hideDialog()"> mdi-close </v-icon>
        </div>
        <div class="text-body-2 pr-3">
          <div class="d-flex justify-space-between mb-4">
            <h3 class="d-inline-block">
              {{ data.title }}
            </h3>
          </div>
          <p>
            {{ data.body }}
            <br /><br />
            {{ data.footer }}
          </p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'DisclaimerTextDialog',

  props: {
    data: {
      type: Object,
      default() {
        return {
          theme: '',
          title: '',
          body: '',
          footer: '',
        };
      },
    },
  },

  data() {
    return {
      dialog: true,
    };
  },

  methods: {
    hideDialog,
  },
};
</script>
<style scoped>
.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
